.tourBody{
    margin: 0px var(--body-left-margin); 
    min-height: 100vh;
}

.logisticsGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--default-padding);
    justify-content: space-between;
}

.logisticsGridItem{
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
    line-height: var(--default-line-height);
    flex: 1 1 auto;
    padding: 0 var(--default-padding);
}

.pricingBox{
    border-style: solid; 
    padding: var(--default-padding); 
    border-color: var(--clr-secondary); 
    border-width: 3px; 
    border-radius: var(--default-border-radius); 
    font-size: var(--default-font-size); 
    font-weight: var(--default-font-weight); 
    line-height: var(--default-line-height); 
    margin-bottom :calc(2*var(--margin-bottom-var)); 
}

ul{
    padding-inline-start: 25px; 
}

.pricingBox div{
    margin: calc(var(--default-padding)*2) 0px;
}

.otherToursTitle{
    text-align: center; 
    margin: calc(2*var(--margin-bottom-var)) 0px; 
}

@media(max-width: 425px){
   .logisticsGrid{
       grid-template-columns: auto; 
       margin: var(--body-left-margin) 0px; 
   }

   .pricingBox{
       margin-bottom: 0px;
   }

   .logisticsGridItem{
       padding: calc(.5*var(--default-padding)); 
   }

   .otherToursTitle{
       margin: var(--body-left-margin) 0px; 
   }
}