

p, .white{
    color: var(--clr-background); 
    font-size: var(--default-font-size); 
    font-size: 1.2rem;
    font-weight: var(--default-font-weight); 
}

.aboutGrid {
    margin: 0px;
    background-color: black;
    min-height: 55%;
    background-image: url("../images/about/aboutBackgroundImage.jpg");
    background-repeat: no-repeat;
    padding-left: var(--body-left-margin);
    padding-left: calc(var(--body-left-margin)/2);
    margin-bottom: calc(-4*var(--margin-bottom-var));

    background: url("../images/about/aboutBackgroundImage.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: calc(-4*var(--margin-bottom-var));
}

.bottomMargin{
    content: ""; 
    height: 2rem; 
}

.aboutTitle{
    color: var(--clr-primary); 
}

.aboutText {
    padding-top: var(--margin-bottom-var); 
    width: 50%;
    max-width: 1100px;
    background: linear-gradient( to right, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.964) 7.4%, hsla(0, 0%, 0%, 0.918) 15.3%, hsla(0, 0%, 0%, 0.862) 23.4%, hsla(0, 0%, 0%, 0.799) 31.6%, hsla(0, 0%, 0%, 0.73) 39.9%, hsla(0, 0%, 0%, 0.655) 48.2%, hsla(0, 0%, 0%, 0.577) 56.2%, hsla(0, 0%, 0%, 0.497) 64%, hsla(0, 0%, 0%, 0.417) 71.3%, hsla(0, 0%, 0%, 0.337) 78.1%, hsla(0, 0%, 0%, 0.259) 84.2%, hsla(0, 0%, 0%, 0.186) 89.6%, hsla(0, 0%, 0%, 0.117) 94.1%, hsla(0, 0%, 0%, 0.054) 97.6%, hsla(0, 0%, 0%, 0) 100% );
    background: hsl(0 0% 0% / .65) 100%;
    position: relative;
}

.aboutText p {
    color: var(--clr-background) !important;
}

.aboutText::after {
    position: absolute;
    height: 100%;
    width: 40px;
    content: '';
    top: 0;
    right: -40px;
    z-index: 2;
    background: linear-gradient( to left,
        hsl(0 0% 0% / 0), 
        hsl(0 0% 0% / .2) 30%, 
        hsl(0 0% 0% /.45) 60%, 
        hsl(0 0% 0% / .65) 100% );
}

.aboutText::before {
    position: absolute;
    height: 100%;
    width: 10%;
    content: '';
    top: 0%;
    left: -10%;
    z-index: 2;
    background: linear-gradient( to right,
        hsl(0 0% 0% / 0), 
        hsl(0 0% 0% / .2) 30%,
        hsl(0 0% 0% /.45) 60%,
        hsl(0 0% 0% / .65) 100% );
}

@media(max-width: 1024px){
    .aboutText{
        width: 60%; 
    }
}

@media(max-width: 768px){
    .aboutText{
        width: 70%; 
    }
}

@media(max-width: 580px){
    .aboutText{
        width: 85%; 
    }
}

@media(max-width: 425px){
    .aboutText{
        width:100%;
        padding-bottom: var(--margin-bottom-var); 
    }

    .aboutGrid{
        padding-bottom:0px;
    }
}
