.thankYouContainer {
    background: url("../images/thankYou/waterfall.jpg") no-repeat center center fixed;
    background-repeat: no-repeat;
    padding: var(--default-padding) 0px;
    padding-left: var(--body-left-margin);
    padding-right: var(--body-left-margin);
    padding: var(--body-left-margin);
    padding-top:0px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-bottom: calc(-4*var(--margin-bottom-var)); 
}

.containerText {
    padding-top: calc(2*var(--margin-bottom-var)); 
    color: var(--clr-primary); 
    color: black;
    font-size: 1.8vw;
    line-height: 2.2vw;
    max-width: 80%;
}

.textRow{
    margin: var(--margin-bottom-var) 0px; 
    color: var(--clr-background); 
}

.containerText {
    background: hsl(0 0% 0% / .6) 100%;
    position:relative; 
}

.containerText::before{
    position: absolute;
    height: 100%;
    width: 10%;
    content: '';
    top: 0%;
    left: -10%;
    z-index: 2;
    background: linear-gradient( to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / .2) 30%, hsl(0 0% 0% /.45) 60%, hsl(0 0% 0% / .6) 100% );
}

.containerText::after {
    position: absolute;
    height: 100%;
    width: 40px;
    content: '';
    top: 0;
    right: -40px;
    z-index: 2;
    background: linear-gradient( to left, hsl(0 0% 0% / 0), hsl(0 0% 0% / .2) 30%, hsl(0 0% 0% /.45) 60%, hsl(0 0% 0% / .6) 100% );
}

@media(max-width:425px){
    .containerText{
        font-weight: 500; 
        font-size: 1.1rem;
        line-height: 1.3rem; 
        padding-bottom: var(--margin-bottom-var); 
    }

    .thankYouContainer{
        padding-bottom:0px; 
    }
}