:root {
    --clr-background: #ffffff;
    --clr-primary: #2f7bbd;
    --clr-primary: #4da5fd;
    --clr-secondary: rgb(247, 196, 0);
    --clr-third: rgb(77, 165, 253);
    --clr-fourth: #C83D3A;
    --clr-black: #000000;
    --margin-bottom-var: 20px;
    --body-left-margin: 60px;
    --numColumns: auto auto auto;
    --grid-gap: 2em;
    --default-padding: 1.5em;
    --default-border-radius: 15px;
    --default-font-size: 1.2rem;
    --font-size-xxl: 1.8rem;
    --font-size-xlarge: 1.5rem;
    --font-size-large: 1.3rem;
    --font-size-small: .9rem;
    --font-size-xsmall: .7rem;
    --font-size-smallish: 1.1rem;
    --default-font-weight: 500;
    --default-line-height: 1.45rem;
    --default-border-width: 3px;
    --menuMargin: 155px;
    --imgWidth: 40px;
    --imgHeight: 20px;
    --day-size: 2rem;
    --calendar-width: 360px;
    --contact-form-height: 40px;
    --imageCardSpeed: 150ms;
    --imageContentSpeed: 400ms;
    --menuOptionSize: 12rem;
    --tabletWidth: 768px;
    --transition-time-default: all .3s;
    --width-medium: 45em;
    --height-large: 3em;
    --width-large: 3em;
    --mobileNavbarHeight: 47px;
}

.gridOption .OptionTitle {
    color: var(--clr-background);
    background: hsl(0 0% 0% / .05) 100%;
}

.bottomBar.fullScreen {
    margin-top: 0px;
}

.embarkmentTitle {
    /* display: flex; */
    gap: var(--default-padding);
}

.blackText, .blackText p {
    color: var(--clr-black);
}

.flex-wrap {
    flex-wrap: wrap;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active+.fp-tooltip {
    padding: .5em;
}

#fp-nav ul li .fp-tooltip.fp-right {
    font-size: var(--font-size-smallish);
    font-weight: bold;
    background: black;
    border-radius: 0.5em;
    opacity: .9;
}

.noMargin {
    margin: 0px;
}

.bodyMargin {
    margin: 0px var(--body-left-margin)
}

.slide.bodyPadding .fp-overflow {
    padding: 0 var(--body-left-margin);
}

.accordion.noIcon .MuiAccordionSummary-content .MuiSvgIcon-root{
    display: none;
}

.fullScreen {
    min-height: 100vh;
}

.slideImgContainer {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}

.fpTitle{
    text-align: center;
}

.no-overflow {
    overflow: hidden !important;
}

.bottomAccordion {
    position: absolute !important;
    z-index: 2 !important;
    bottom: 0px !important;
}

.invertArrows .fp-arrow {
    border-color: transparent transparent transparent hsl(0 0% 0% / .85) !important;
}

.invertArrows .fp-prev {
    border-color: transparent hsl(0 0% 0% / .85) transparent transparent !important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    background: var(--clr-secondary) !important;
    height: 8px !important;
    width: 8px !important;
    margin: -4px 0 0 -4px !important;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
    width: 16px !important;
    height: 16px !important;
    margin: -8px 0 0 -8px !important;
}

.fp-watermark {
    display: none !important;
}

.fp-prev {
    left: 3rem !important;
}

.fp-next {
    right: 3rem !important;
}

.desktopNavbar {
    width: 100%;
    z-index: 1000;
}

.desktopNavbar.fixed {
    position: fixed;
}

.dark {
    background-color: black;
}

.homePanel {
    margin: 0 var(--body-left-margin);
}

.slideImgContainer {
    position: absolute;
    top: var(--mobileNavbarHeight);
}

.desktopNavbarBottom.scroll {
    position: sticky;
    z-index: 1000;
    width: 100%;
    top: 0px;
    align-items: center;
}

.tripadvisorWidgetContainer {
    /* position:absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1000; */
}

.changeEmbarkmentContainer {
    position: relative;
    width: 15%;
    display: flex;
    padding: calc(.3 * var(--default-padding)) var(--default-padding);
    transition: all .3s;
}

.changeEmbarkmentIcon {
    cursor: pointer !important;
    transition: all .3s !important;
    flex: 1 1 auto;
    font-size: var(--font-size-large) !important;
    align-self: center;
    color: var(--clr-background);
}

.changeEmbarkmentLabel {
    display: inline-flex;
    align-self: center;
    flex: 0 0 auto;
    color: var(--clr-background);
    font-weight: bold;
    position: absolute;
    left: -10em;
}

.changeEmbarkmentIcon.selected {
    color: var(--clr-secondary);
}

.changeEmbarkmentIcon:hover {
    font-size: var(--font-size-xxl) !important;
}

.MuiDialog-paper {
    height: 100vh !important;
}

.MuiDialog-paper .contactForm {
    padding: 0;
    margin-top: 0;
}

.infoIcon {
    padding: 0 .3em;
    font-size: var(--font-size-small) !important;
    opacity: .8;
}

.tooltip {
    width: 20rem;
}

* {
    /* max-width: 100vw; */
    /* overflow-x:hidden; */
}

.transportationOptionIcon {
    color: var(--clr-fourth);
}

p {
    margin: 0px;
}

.flexed {
    display: flex !important;
}

.centered {
    justify-content: center;
}

.flexed.col {
    flex-direction: column;
    gap: var(--default-padding);
}

.flexed.row {
    flex-direction: row;
    gap: var(--default-padding);
}

.flexed.gap {
    gap: var(--default-padding);
}

.flexed.apart {
    justify-content: space-between;
}

.bookingContent {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 20%);
}


.tourCard {
    flex: 1 1 25%;
}

.flexMax {
    flex: 1 1 auto;
}

.flexMin {
    flex: 0 0 auto;
}

.padded {
    padding: var(--default-padding);
}

.padded-top {
    padding-top: var(--default-padding);
}

.padded-bottom {
    padding-bottom: var(--default-padding);
}

.padded-top-bottom {
    padding: var(--default-padding) 0;
}

.padded-left {
    padding-left: var(--default-padding);
}

.padded-sides {
    padding: 0 var(--default-padding);
}

.dropdown {
    transition: all .3s;
    height: var(--font-size-smallish);
}

.dropdown.rotate {
    transform: rotate(180deg);
}

.dropdownMenuOption {
    padding: var(--default-padding) 1ch;
    vertical-align: middle;
    display: flex;
    align-items: center;
    font-size: var(--default-font-size);
    font-weight: bold;
    color: var(--clr-background);
}

.iconTextContainer {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.iconTextContainer .iconText {
    padding-left: 1ch
}

.faqTitle {
    margin-top: 3.5rem;
    text-align: center;
}



.pricingBox .imageCardBtn {
    text-align: center;
    padding: var(--default-padding) 10%;
    -webkit-user-select: none;
    user-select: none;
    width: 35%;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.filterButton {
    height: 2rem;
    width: 2rem;
}

.filterIcon {
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-right: auto;
    cursor: pointer;
}

.filterIcon .filterCircle:nth-child(1) {
    right: .25rem;
    top: .25rem;
}

.filterIcon .filterCircle:nth-child(2) {
    left: .25rem;
    top: .8rem;
}

.filterIcon .filterCircle:nth-child(3) {
    left: 1rem;
    top: 1.35rem;
}

.filterIcon .filterLine:nth-child(4) {
    top: .4rem;
}

.filterIcon .filterLine:nth-child(5) {
    top: .95rem;
}

.filterIcon .filterLine:nth-child(6) {
    top: 1.5rem;
}

.filterContainer {
    position: relative;
}

.filterBy {
    left: 2.2rem;
    top: 0.5rem;
    position: absolute;
    font-size: calc(.8 * var(--default-font-size));
    cursor: pointer;
    transition: all .3s ease-in-out;
    user-select: none;
}

.filterBy:hover,
.filterContainer:hover>.filterBy {
    color: var(--clr-primary);
}

.filterBy.hidden {
    visibility: hidden;
}

.filterCircle {
    border-width: 1px;
    height: .3rem;
    width: .3rem;
    border-style: solid;
    border-radius: .3rem;
    background-color: white;
    border-color: var(--clr-primary);
    position: absolute;
    border-width: 2px;
    transition: all .3s;
}

.filterOptionContainer {
    border-radius: var(--default-border-radius);
    max-width: 0rem;
    transition: all .3s;
    position: absolute;
    left: 2.2rem;
    top: 0rem;
    overflow: hidden;
}


.filterOption {
    position: relative;
    font-size: calc(.7 * var(--default-font-size));
    background-color: var(--clr-primary);
    color: white;
    margin-bottom: -1px;
    max-height: 0px;
    transition: max-height .2s linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterOptionTitle {
    color: var(--clr-fourth);
    font-weight: 600;
    text-align: center;
    max-height: 0px;
    transition-delay: .3s;
    transition: all .3s;
    border-style: solid;
    border-top-left-radius: var(--default-border-radius);
    border-top-right-radius: var(--default-border-radius);
    border-bottom-width: 0px;
}

.filterOptionContainer.clicked {
    max-width: 12rem;
    width: 12rem;
    border-color: var(--clr-primary);
    z-index: 1;
}

.filterOptionContainer.clicked>.filterOption {
    max-height: 2rem;
    height: 2rem;
    transition-delay: 300ms;
}

.filterOption::before {
    content: '';
    position: absolute;
    left: 0px;
    height: 100%;
    width: 0px;
    background-color: white;
    transition: all .3s;
}

.filterOption.chosen {
    font-weight: 600;
    color: var(--clr-secondary);
}


.filterOption:hover {
    font-weight: 600;
}

.filterOption:hover::before {
    width: 3px;
}

.filterOption.chosen::before {
    width: 3px;
    background-color: var(--clr-secondary);
}

.filterOptionContainer.clicked>.filterOptionTitle {
    padding: calc(.5 * var(--default-padding));
    max-height: 150px;
}

.filterLine {
    position: absolute;
    width: 2rem;
    height: 3px;
    background-color: var(--clr-primary);
    z-index: -1;
}

.filterCircle::before {
    left: -1.25rem;
}

.filterIcon:hover>.filterCircle:nth-child(1) {
    right: 1rem;
}

.filterIcon:hover>.filterCircle:nth-child(2) {
    transform: translateX(1rem);
}

.filterIcon:hover>.filterCircle:nth-child(3) {
    transform: translateX(-.75rem);
}

.filterIcon.clicked .filterCircle:nth-child(1) {
    transform: translateX(.25rem);
}

.filterIcon.clicked .filterCircle:nth-child(2) {
    transform: translateX(-.25rem);
}

.filterIcon.clicked .filterCircle:nth-child(3) {
    transform: translateX(.25rem);
}

.filterIcon.clicked * {
    border-color: var(--clr-fourth);
}

.filterIcon.clicked *::before,
.filterIcon.clicked .filterLine {
    background-color: var(--clr-fourth);
}

.filterButton {
    display: none;
}

.filterButton option {
    position: absolute;
    width: 5rem;
    height: 2rem;
}

.filterButton.clicked {
    display: block;
}

.tourBundleGrid {
    display: grid;
    border-width: 2px;
    border-radius: var(--default-border-radius);
    grid-template-columns: 78% 20%;
    grid-column-gap: var(--default-padding);
    overflow: hidden;
}

.tourBundleGridSavings {
    font-size: 1rem;
    color: var(--clr-primary);
    font-weight: 600;
}

.tourBundleGridLabel {
    text-align: center;
    padding: var(--default-padding) 0px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-size: 1.2rem;
}

.tourBundleGridCentered {
    text-align: center;
    font-size: 1.3rem;
    border-style: solid;
    border-color: var(--clr-secondary);
    border-radius: var(--default-border-radius);
    border-width: var(--default-border-width);
    width: calc(100% - 2*var(--default-border-width));
    height: 5rem;
    border-radius: var(--default-border-radius);
    margin-bottom: var(--default-padding);
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-columns: 80% 20%;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.seeTour {
    opacity: 0;
    color: white;
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: calc(3 * var(--default-padding));
    right: calc(3 * var(--default-padding));
    transition-delay: .4s;
    font-weight: bold;
    user-select: none;
    font-size: var(--font-size-small);
}

.seeTour:hover {
    transition-delay: 0s;
    transform: scale(1.05);
}

.tourCardBundle:hover>.seeTour {
    opacity: 1;
}

.tourBundleGridLink {
    cursor: pointer;
    font-size: var(--default-font-size);
    position: absolute;
    color: white;
    font-weight: 600;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    padding: calc(.5 * var(--default-padding));
}

.tourBundleItems {
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex: 1 1 auto;
    gap: var(--default-padding);
}

.bundleLogoGrid {
    display: grid;
    grid-row-gap: var(--default-padding);
}

.bundleLogo {
    border-style: solid;
    border-radius: var(--default-border-radius);
    border-color: var(--clr-fourth);
    overflow: hidden;
    height: 5rem;
}

.bundleLogo.chosen {
    transform: scale(1.05);
    border-color: var(--clr-primary);
}

.bundleLogo.chosen>.bundleLogoItem {
    font-size: 1.1rem;
}

.bundleLogo.label {
    border-color: var(--clr-secondary);
    font-size: 1.3rem;
    font-size: .9rem;
    font-weight: 600;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--default-padding);
}

.bundleLogo.empty {
    border-style: none;
}

.tourCardBundle {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: var(--default-border-radius);
    background-size: cover;
    transition: all .5s ease-in-out;
}



.tourCardBundle:hover {
    /* transform: scale(1.1);  */
    /* max-height: 100%;  */
    /* max-width: 100%;  */
}

.noMargin {
    margin: 0 !important;
}

.logoImg {
    position: absolute;
    top: 13px;
    left: 5px;
}

*.lazy,
* .lazy {
    background-image: none;
    background-color: var(--clr-secondary);
}

a {
    text-decoration: none;
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4 {
    font-weight: 700;
    margin: 0px;
}

h1 {
    font-size: 2.4em;
    color: var(--clr-primary);
}

h2 {
    font-size: 2.4em;
    color: var(--clr-primary);
}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.3em;
}

.homeTextBlock {
    color: var(--clr-black);
    font-size: var(--default-font-size);
}

.flag {
    margin: 30px auto;
    position: absolute;
    width: 3.5rem;
    height: 1.5rem;
    top: -4.5rem;
    right: 9rem;
    transition: all .5s;
}

.cruiseContainer:hover>.flag {
    top: -5.5rem;
    right: 9rem;
}

.flag::before,
.flag::after {
    content: '';
    background-color: var(--clr-secondary);
    position: absolute;
    height: 135%;
    width: 5px;
    top: 1px;
    border-radius: 1rem;
}

.flag::before {
    left: 5px;
    height: 250%;
}

.flag::after {
    right: 5px;
}

.flagTop {
    position: relative;
    width: 33%;
    height: 1.2rem;
    display: flex;
    margin: 0 auto;
    border-style: solid;
    border-color: var(--clr-secondary) transparent transparent transparent;
    border-radius: 1rem;
    border-width: 5px;
}

.flagTop::before,
.flagTop::after {
    border-style: solid;
    border-color: transparent transparent var(--clr-secondary) transparent;
    border-radius: 1rem;
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: -135%;
    border-width: 5px;
}

.flagTop::before {
    left: -1.1rem;
}

.flagTop::after {
    right: -1.1rem;
}

.desktopNavbarText {
    font-weight: 700;
    font-size: 3rem;
    line-height: 56px;
    color: var(--clr-fourth);
    padding-top: 19px;
    text-align: center;
    min-height: 55px;
    background-color: var(--clr-primary);
    background-color: white;

    display: none;
}

.desktopNavbarLogoSpace {
    position: absolute;
    background-color: white;
    height: 150px;
    width: 150px;
    border-style: solid;
    border-color: white;
    border-radius: 85px;
    z-index: 2;
    background: linear-gradient(150deg, rgb(77, 165, 273), #000000 117%);
    left: 0%;
    top: -2%;
    z-index: 1001;
    top: -20px;
    left: 5px;
}

.desktopLogo {
    width: 94px;
    height: 108px;
    position: absolute;
    top: 0px;
    left: 2%;
}

.desktopLogoBorder {
    width: 100px;
    height: 100px;
    position: absolute;
    background-color: var(--clr-background);
    border-radius: 50%;
    left: 1.8%;
    top: 9px;
}

.desktopNavbarBottom {
    width: 100%;
    display: block;
    background-color: var(--clr-primary);
}

.desktopMenuContainer {
    margin-left: var(--menuMargin);
    display: flex;
    justify-content: space-evenly;
}

.desktopMenuOption,
.desktopMenuLink {
    display: inline-block;
    text-align: center;
    font-weight: 500;
    font-size: var(--font-size-large);
    color: #ffffff;
    cursor: pointer;
    position: relative;
    flex: 1 1 auto;
    padding: calc(.5 * var(--default-padding)) var(--default-padding);
}

.desktopMenuOption a {
    color: #ffffff;
    text-decoration: none;
}

.desktopNavbarStripe {
    position: absolute;
    background-color: var(--clr-background);
    height: 3px;
    bottom: 0px;
    width: 0%;
    left: 50%;
    transition: all .3s ease;
}

.desktopNavbarTourStripe {
    height: 100%;
    opacity: 0;
    top: 0;
    width: 3px;
    position: absolute;
    background-color: var(--clr-background);
    left: 0px;
    transition: all .3s ease;
}

#desktopLand a:hover .desktopNavbarTourStripe,
#desktopCruise a:hover .desktopNavbarTourStripe {
    opacity: 1;
}

.desktopMenuOption:hover .desktopNavbarStripe,
.desktopMenuLink:hover .desktopNavbarStripe {
    width: 100%;
    left: 0;
}

.desktopDropdown {
    width: var(--menuOptionSize);
}


#desktopLand,
#desktopCruise {
    z-index: 1;
    width: 100%;
    max-height: 0px;
    display: block;
    visibility: hidden;
    position: absolute;
    margin-top: 6px;
    background-color: var(--clr-primary);
    color: var(--clr-background);
    text-align: left;
    transition: max-height .5s ease-out, height .5s ease-out, visibility .2s ease-in-out;
    width: max-content;
}


#desktopLand a,
#desktopCruise a {
    position: relative;
    opacity: 0;
    transition: opacity .2s;
}

.desktopDropdown:hover #desktopLand,
.desktopDropdown:hover #desktopCruise {
    max-height: 650px;
    visibility: visible;
    height: auto;
}

.desktopDropdown:hover #desktopLand a,
.desktopDropdown:hover #desktopCruise a {
    opacity: 1;
}

.slideImage {
    display: block;
    overflow: hidden;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}

.slideShowContainer {
    margin-bottom: var(--margin-bottom-var);
    width: 100%;
    position: relative;
    background-color: black;
    height: 750px;
    overflow: hidden;
    background-repeat: no-repeat;
    background: center center;
    background-size: cover;
    background: none;
    background-image: none;
    background-color: black;
}

.legend {
    font-size: var(--default-font-size) !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    border-top: .5em solid transparent !important;
    border-bottom: .5em solid transparent !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 1em solid white !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 1em solid white !important;
}

.carousel .control-dots .dot {
    height: 1em !important;
    width: 1em !important;
}

.carouselStyle {
    height: 100% !important;
}

.carouselContainer {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.slide {
    text-align: center;
    height: 100%;
    width: 100%;
}

.homeContainer.toggled {
    max-height: 100vh;
}

.homeContainer.toggled>*:not(.contactBody) {
    user-select: none;
    cursor: none;
}

.slideShowText {
    position: absolute;
    top: 10%;
    left: var(--body-left-margin);
    color: var(--clr-background);
    font-size: 3rem;
    font-weight: 700;
    line-height: 4.3rem;
    z-index: 2;
}

.tourBundleButtonContainer {
    padding: var(--default-padding);
    overflow: hidden;
}

.tourBundleButton {
    width: 100%;
    padding: var(--default-padding) 0;
    border-radius: var(--default-border-radius);
    vertical-align: middle;
    font-size: var(--default-font-size);
    font-weight: bold;
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: var(--clr-primary);
    border-style: solid;
    border-width: 2px;
    border-color: var(--clr-primary);
}

.tourBundleGridCentered:hover {
    border-color: var(--clr-primary);
}

.tourBundleButton:hover,
.tourBundleGridCentered:hover>.tourBundleButtonContainer .tourBundleButton {
    color: white;
    background-color: var(--clr-primary);
}

.homeBodyContainer {
    padding-left: var(--body-left-margin);
    padding-right: var(--body-left-margin);
    max-width: 100%;
    position: relative;
    overflow: hidden;
}

.introText {
    font-weight: var(--default-font-weight);
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
}

.optionsGrid {
    display: flex;
    gap: var(--default-padding);
    width: 100%;
}

.gridOption {
    cursor: pointer;
    padding: var(--default-padding);
    border-style: solid;
    border-color: var(--clr-secondary);
    border-width: 3px;
    border-radius: var(--default-border-radius);
    font-size: var(--default-font-size);
    font-weight: var(--default-font-weight);
    line-height: var(--default-line-height);
    transition: all .3s;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid rgb(227, 233, 237);
    box-sizing: border-box;
    transition: var(--transition-time-default);
    background-size: cover;
    width: 100%;
    flex: 1 1 auto;
    transition: all .3s;
    position: relative;
    height: 45vh;
}

.gridOptionItemButton {
    width: calc(100% - 2 * var(--default-padding));
    display: flex !important;
    justify-self: center !important;
    transition: all 1s;
    position: absolute !important;
    bottom: var(--default-padding);
}

.gridOptionItemButton.hidden {
    opacity: 0;
}

.gridOption:hover {
    transform: scale(1.02);
}

.gridOption .accordion {
    background: none;
    border: none;
    box-shadow: none;
}

.gridOption .accordion * {
    color: var(--clr-background) !important;
    font-weight: bold;
}

.gridOption .accordion {
    background: hsl(0 0% 0% / .45) 100%;
}

.gridOption .accordion h3 {
    font-size: var(--font-size-large);
}

/* .gridOption .accordion .accordionDetails {
    background: hsl(0 0% 0% / .45) 100%;
} */

.gridOption .accordion .MuiSvgIcon-root {
    font-size: 2.5em;
}

.gridOption.full {
    max-height: 2000px;
}

.accordion h1,
.accordion h2 {
    padding-left: 16px;
}

.OptionTitle {
    flex: 1 1 auto;
    text-align: center;
    margin-bottom: var(--margin-bottom-var);
    /* color: var(--clr-background); */
    /* background: hsl(0 0% 0% / .05) 100%; */
}

.OptionCheckboxContainer {
    flex: 0 0 auto;
}

.OptionCheckbox {
    height: var(--height-large);
    width: var(--width-large);
    background-color: var(--clr-background);
    border-radius: var(--default-border-radius);
    position: relative;
}

.OptionCheckbox.checked::after {
    content: '';
    position: absolute;
    border-right: solid 0.5em var(--clr-secondary);
    border-bottom: solid 0.5em var(--clr-secondary);
    transform: rotate(45deg);
    width: 25%;
    height: 60%;
    top: 5%;
    left: 26%;
}

.OptionText {
    color: var(--clr-black);
    visibility: visible;
    transition: all .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 1000px;
    transition-delay: .3s;
}

.OptionText.short,
.OptionText.short .homeTextBlock {
    visibility: hidden;
    transition: all .3s;
    max-height: 0px;
    transition-delay: .3s;
    transition: visibility 0s;
}

.grid-container {
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;
    grid-template-columns: var(--numColumns);
    max-height: 1400px;
    transition: all 3s;

    display: flex;
    flex-flow: wrap;
    position: relative;
    gap: var(--default-padding)
}

.grid-container.maxed {
    max-height: 6000px;
    transition: all 3s;
}

.imageCard {
    color: var(--clr-black);
    background-size: cover;
    /* padding: 10rem 0 0;
    padding: 12rem 0 0;
    border-radius: var(--default-border-radius);
    overflow: hidden;
    transition: transform var(--imageCardSpeed) ease;
    max-height: 55%; */

    padding: 22rem 0 0;
    border-radius: var(--default-border-radius);
    overflow: hidden;
    transition: transform var(--imageCardSpeed) ease;
    max-height: 38%;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    height: 100%;
}

.imageCardContent {
    padding: var(--default-padding);
    background: linear-gradient(hsl(0 0% 0% / 0), hsl(0 0% 0% / .3) 20%, hsl(0 0% 0% / 1) 100%);
    transform: translateY(130px);
    transition: transform var(--imageContentSpeed) ease;
}

.imageCardContent.smallCard {
    transform: translateY(0px);
}

.imageCardContent.smallCard>.imageCardCaption {
    font-size: 1.2rrem;
}

.imageCard.small {
    width: 25ch;
    padding: 6rem 0 0;
}

.imageCard.purchaseCard {
    width: 100%;
    padding: 0;
    padding-bottom: 3.5rem;
    padding: 0;
    height: 100%;
    max-height: 100%;
}

.imageCardContent>*:not(.imageCardCaption) {
    opacity: 0;
    transition: opacity var(--imageContentSpeed) linear;
    transition-delay: 500ms;
}

.imageCard.purchaseCard .imageCardContent {
    transform: none;
    opacity: 1;
    padding: .5em;
    background: linear-gradient(hsl(0 0% 0% / 0), hsl(0 0% 0% / .3) 20%, hsl(0 0% 0% / .7) 100%);
    height: 100%;
}

.imageCard.purchaseCard .imageCardContent>* {
    opacity: 1;
}

.imageCardCaption {
    position: relative;
    color: var(--clr-background);
    width: max-content;

}

.imageCardCaption::after {
    content: "";
    position: absolute;
    height: 3px;
    background: var(--clr-primary);
    width: calc(100% + 1.5rem);
    bottom: -5px;
    left: calc(1.5rem * -1);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform var(--imageContentSpeed) ease;
}

.imageCard.purchaseCard .imageCardCaption::after {
    content: "";
    height: 0px;
}

.imageCard.purchaseCard .imageCardCaption {
    font-size: 1.4rem;
    font-weight: bolder;
    padding: 2px;
}

.imageCardBody {
    text-indent: 0px;
    padding: 0px;
    margin: 0px;
    color: var(--clr-background);
    margin-bottom: 1rem;
}

.imageCardBtns {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: var(--default-padding);
}

.imageCardBtn {
    cursor: pointer;
    text-align: center;
    display: inline;
    text-decoration: none;
    color: var(--clr-background);
    background-color: var(--clr-primary);
    padding: .5em 1.25em;
    font-weight: 600;
    border-radius: .25rem;
    transition: all var(--imageCardSpeed) ease;
}

.imageCardBtn:hover,
.imageCardBtn:focus {
    background-color: var(--clr-secondary);
    color: var(--clr-black);
}


.imageCard:hover .imageCardCaption::after {
    transform: scaleX(1);
}

.imageCard:hover .imageCardContent {
    transform: translateY(0);
    transition-delay: var(--imageContentSpeed);
}

.imageCard:hover .imageCardContent>* {
    opacity: 1;
}

.bottomBar {
    background-color: var(--clr-primary);
    margin-top: calc(4*var(--margin-bottom-var));
    justify-content: space-between;
    padding: 20px 40px;
    padding: calc(2*var(--default-padding)) calc(4*var(--default-padding));
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bottomBarImgContainer {
    display: flex;
    position: relative;
}

.bottomBarLogoText {
    display: inline-block;
    position: absolute;
    left: 35%;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 40px;
    color: var(--clr-background);
}

.bottomBarLogoMenu {
    color: var(--clr-background);
    font-weight: var(--default-font-weight);
    font-size: calc(1.2 * var(--default-font-size));
}

.bottomBarLogoGrid {
    font-size: var(--default-font-size);
    text-decoration: none;
    display: grid;
    margin-top: 20px;
    grid-template-columns: auto auto;
    grid-column-gap: var(--default-padding);
    grid-row-gap: 0;
}

.bottomBarLogoGrid a {
    color: var(--clr-background);
    transition: all .3s ease-in-out;
}

.bottomBarLogoGrid a:hover {
    color: var(--clr-secondary);
}

.bottomBarLogoTextBack {
    margin-left: -10px;
}

.followUsTitle {
    display: inline-block;
}

.TripadvisorIcon {
    padding: 10px 0px;
}

.TripadvisorIcon:hover .owlPupil {
    transform: scale(2.5);
}

.owlEye {
    border-style: solid;
    border-color: var(--clr-background);
    z-index: 3;
    border-radius: 50%;
    border-width: calc(var(--imgHeight)*.15);
    padding: calc(var(--imgWidth)*.1);
    background-color: var(--clr-primary);
}

.owlTop {
    position: absolute;
    border-top: calc(var(--imgWidth)*.08) solid var(--clr-background);
    border-left: calc(var(--imgWidth)*.08) solid var(--clr-background);
    border-right: calc(var(--imgWidth)*0.08) solid var(--clr-background);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: var(--clr-primary);
    z-index: 3;
    height: 15%;
    width: 47%;
    height: 8px;
    left: 17%;
    top: -3px;
}

.owlTriangle {
    position: absolute;
    top: 1px;
    width: 0;
    height: 0;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    z-index: 2;
    border-top: 100px solid var(--clr-background);
    border-left: var(--imgHeight) solid transparent;
    border-right: var(--imgHeight) solid transparent;
    border-top: var(--imgHeight) solid var(--clr-background);
}

.owlHead {
    position: relative;
    background-color: var(--clr-primary);
    width: var(--imgWidth);
    height: var(--imgHeight);
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 1;
}

.owlPupil {
    background-color: var(--clr-background);
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: 3;
}

.facebookIcon {
    background-color: var(--clr-primary);
    width: var(--imgWidth);
    height: 40px;
    position: relative;
    overflow: hidden;
    border-style: solid;
    border-radius: 4px;
    border-width: 4px;
    border-color: var(--clr-background);
    display: inline-block;
    margin: 0px 10px;
    vertical-align: middle;
}

.facebookIcon * {
    transition: transform .2s ease-in-out;
}

.facebookCurve {
    position: absolute;
    width: 12%;
    height: 80%;
    left: 45%;
    top: 20%;
    border-style: solid;
    border-width: 0px;
    border-top-width: 5px;
    border-left-width: 5px;
    border-color: var(--clr-background);
    border-top-left-radius: 15px;
}

.footerLogoSpace {
    background-color: white;
    height: 350px;
    width: 350px;
    border-style: solid;
    border-color: white;
    border-radius: 175px;
    z-index: 2;
    background: linear-gradient(150deg, rgb(77, 165, 273), #000000 117%);
    left: 0%;
    top: -2%;
    z-index: 1001;
    top: -20px;
    left: 0px;
}

.facebookLine {
    position: absolute;
    width: 32%;
    top: 50%;
    border-style: solid;
    height: 1px;
    background-color: var(--clr-background);
    border-width: 2px;
    border-color: var(--clr-background);
    border-radius: 5px;
    left: 33%;

}

.facebookTRRound {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: var(--clr-background);
    top: 22%;
    left: 62%;
    top: 8px;
}

.facebookIcon:hover .facebookLine,
.facebookIcon:hover .facebookCurve,
.facebookIcon:hover .facebookTRRound {
    transform: translateX(11px);
}

.instagramInnerCircle {
    position: absolute;
    width: 40%;
    height: 40%;
    top: 20%;
    left: 20%;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: var(--clr-background);
}

.facebookIcon:hover .instagramDot {
    transform: translateX(-12px) translateY(12px) scale(3.2);
}

.instagramDot {
    position: absolute;
    width: 15%;
    height: 15%;
    border-radius: 50%;
    background-color: var(--clr-background);
    left: 75%;
    top: 10%;
}

.loadMoreToursBtn {
    padding-left: var(--default-padding) !important;
    padding-right: var(--default-padding) !important;
    background-color: var(--clr-primary) !important;
    font-size: var(--default-font-size) !important;
    font-weight: 600 !important;
    color: var(--clr-background) !important;
}

.loadMoreToursBtn .MuiButton-label {
    color: var(--clr-background) !important;
}

.loadMoreToursBtn.yellow {
    background-color: var(--clr-secondary) !important;
}

.loadMoreToursBtn.yellow .MuiButton-label {
    color: black !important;
}

.next {
    right: 2%;
    border-radius: 0px 5px 0px 0px;
    border-style: solid;
    border-width: 6px 6px 0px 0px;
    transform: rotate(45deg);
}

.prev {
    left: 2%;
    border-radius: 0px 5px 0px 0px;
    border-style: solid;
    border-width: 6px 6px 0px 0px;
    transform: rotate(-135deg);
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    color: white;
    font-weight: 600;
    font-size: 30px;
    transition: .6s ease;
    user-select: none;
    padding: 15px;
    z-index: 2;
}

.prev:hover,
.next:hover {
    color: var(--clr-third);
}

.mobileNavbar {
    display: none;
}

.companyLogoMobile {
    text-align: left;
    float: left;
}

.companyLogoContainer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    position: relative;
}

.navText {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
    color: var(--clr-background);
    padding: 30px 0px;
}

.hamburger,
.smallBurger {
    padding-left: 5px;
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 10% 10% 10%;
    align-content: space-evenly;
    justify-content: normal;
    cursor: pointer;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.smallBurger {
    width: 100%;
    height: 100%;
    grid-row-gap: 20%;
    padding: 0px;
    grid-template-rows: 20% 20% 20%;
}

.mid {
    transition: opacity .3s, visibility .3s;
}

.left,
.right {
    transform: rotate(0deg);
    transition: none;
}


.mid,
.left,
.right {
    background-color: var(--clr-background);
    border-radius: 10px;
    font-size: 12px;
}

.hamburger.toggled .mid,
.smallBurger.toggled .mid {
    display: none;
    opacity: 0;
    transition: opacity .3s, visibility 0s;
}

.hamburger.toggled .right,
.smallBurger.toggled .right {
    transform: translateX(0%) rotate(-45deg);
    transition: transform 200ms ease-in-out 25ms;
}

.hamburger.toggled .left,
.smallBurger.toggled .left {
    transform: translateY(260%) rotate(45deg);
    transition: transform 200ms ease-in-out 25ms;
}

.smallBurger.toggled .left {
    transform: translateY(210%) rotate(45deg);
}

.dropdownContents {
    position: absolute;
    left: 0px;
    margin: 0px;
    padding: 0px;
    transition: all .3s;
    z-index: 100;
    top: 92px;
}

.dropdownOption {
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    visibility: hidden;
    max-height: 0px;
    display: block;
    color: var(--clr-background);
    background-color: var(--clr-primary);
    padding: var(--default-padding);
    font-size: calc(var(--default-font-size)*.7);
    font-weight: 500;
    display: grid;
    grid-template-columns: 80% 20%;
    min-width: 180px;
}

.dropdownOptionText {
    padding: 5px 0px;
}

.dropdownOption.show {
    padding-left: 10px;
    visibility: visible;
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 40px;
}

.tourParagraph {
    visibility: hidden;
    max-height: 0px;
    transition: all .3s;
}

.tourParagraph.show {
    visibility: visible;
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 1000px;
}

.mobileSubmenuWrapper {
    height: 0px;
}

.mobileSubmenuWrapper.show {
    height: auto;
}

.hamburgerContainer {
    padding: 10px 5px;
}

#TA_certificateOfExcellence255 {
    position: absolute;
    height: 210px;
    left: -10px;
}

.deleteMe {
    position: absolute;
}

.questionBox {
    padding: 1rem 0rem;
}

.question {
    color: var(--clr-fourth);
    font-weight: 500;
    padding-bottom: .3rem;
    font-size: 1.25rem;
}

.answer {
    line-height: 1.25rem;
    font-size: 1.05rem;
}

.prettyLink {
    text-decoration: underline;
    color: var(--clr-primary);
}

.safetyList {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 1em;
}

.imageBodyGrid {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-content: space-evenly;
}

.price {
    color: var(--clr-secondary);
    font-weight: 500;
}

.grid-container-bundle {
    display: block;
}

.bundleRow {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: calc(4* var(--default-padding)) 0;
}

.bundleLogoItem {
    display: block;
    text-align: center;
}

.showArrowContainer {
    position: relative;
    height: 2rem;
    width: 2rem;
    border-style: solid;
    border-width: 2px;
    border-color: var(--clr-fourth);
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    vertical-align: middle;
}

.showContainer:hover>.showArrowContainer {
    background-color: var(--clr-fourth);

}

.showContainer:hover>.showArrowContainer .showArrow {
    border-color: var(--clr-background);
    transform: rotate(-45deg);
}

.showMoreText {
    padding-right: var(--default-padding);
    cursor: pointer;
    font-size: calc(var(--default-font-size)*1.2);
    font-weight: 600;
    user-select: none;
}

.showArrow {
    position: absolute;
    left: 0.55rem;
    top: 0.45rem;
    height: 0.7rem;
    width: 0.7rem;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    transform: rotate(45deg);
    border-color: var(--clr-fourth);
    transition: all .3s;
}

.showArrow.toggled {
    transform: rotate(-135deg);
}

.cruiseWheel {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0.75rem;
    right: 0rem;
    z-index: 0;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.islandCruiseWheel {
    top: 4.5rem;
    left: 1rem;
}

.cruiseWheelInner {
    position: relative;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 50%;
    background-color: white;
    border-color: var(--clr-secondary);
}

.cruiseWheelInner::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    top: 0%;
    left: 0%;
    background-color: white;
    z-index: 3;
}

.cruiseWheelSunFace {
    position: relative;
    height: 70%;
    width: 70%;
    background-color: white;
    z-index: 4;
    border-radius: 50%;
    left: 10%;
    top: 10%;
    border-style: solid;
    border-color: var(--clr-secondary);
    overflow: hidden;
}

.sunFaceLeftCheek {
    position: absolute;
    background-color: var(--clr-secondary);
    border-color: var(--clr-secondary);
    width: 58%;
    border-top-right-radius: 46%;
    height: 75%;
    left: -12%;
    bottom: 0%;
}

.sunFaceLeftCheek::before {
    content: '';
    position: absolute;
    height: 24%;
    width: 41%;
    background-color: red;
    background-color: white;
    right: 31%;
    top: 9%;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 70%;
    border-top-right-radius: 70%;
}

.sunFaceLeftCheek::after {
    border-style: solid;
    border-color: var(--clr-secondary);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-right-radius: 46%;
    right: -4px;
    border-width: 4px;
    top: 0px;
}

.sunFaceRightCheek {
    position: absolute;
    background-color: var(--clr-secondary);
    border-color: var(--clr-secondary);
    width: 58%;
    border-top-left-radius: 46%;
    height: 75%;
    right: -12%;
    bottom: 0%;
}

.sunFaceRightCheek::after {
    border-style: solid;
    border-color: var(--clr-secondary);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-left-radius: 46%;
    left: -4px;
    border-width: 4px;
    top: 0px;
}

.sunFaceRightCheek::before {
    content: '';
    position: absolute;
    height: 24%;
    width: 41%;
    background-color: red;
    background-color: white;
    left: 31%;
    top: 9%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 70%;
    border-top-left-radius: 70%;
}

.leftNostril {
    position: absolute;
    height: 39%;
    width: 20%;
    right: 0px;
    top: 17%;
    background-color: white;
    background-color: white;
    position: absolulte;
    border-bottom-left-radius: 80%;
}

.leftNostril::after {
    position: absolute;
    content: '';
    height: 45%;
    width: 120%;
    bottom: -13%;
    background-color: white;
    left: -43%;
    border-radius: 50%;
}

.rightNostril {
    position: absolute;
    height: 39%;
    width: 20%;
    left: 0px;
    top: 17%;
    background-color: white;
    background-color: white;
    position: absolulte;
    border-bottom-right-radius: 80%;
}

.rightNostril::after {
    position: absolute;
    content: '';
    height: 45%;
    width: 120%;
    bottom: -13%;
    background-color: white;
    right: -43%;
    border-radius: 50%;
}

.kylieJennerLips {
    position: absolute;
    background-color: white;
    width: 40%;
    height: 1%;
    z-index: 10;
    top: 0p;
    top: 0px;
    border-style: solid;
    border-width: 4px;
    top: 76%;
    left: 20%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-width: 0px;
    border-color: white;
}

.kylieJennerLips::after {
    content: '';
    position: absolute;
    width: 52%;
    border-style: solid;
    border-color: white;
    background-color: white;
    bottom: -0.35rem;
    border-width: 1px;
    left: 19%;
    height: 0.1rem;
    border-radius: 0px 0px 50% 50%;
}

.cruiseWheelInner .bar:nth-child(2) {
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
}

.cruiseWheelInner .bar:nth-child(3) {
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(2) {
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(3) {
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(4) {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(5) {
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(6) {
    -moz-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
}

.islandCruiseWheel .cruiseWheelInner::after {
    top: -1%;
}

.islandCruiseWheel .cruiseWheelInner .cruiseWheelSunFace {
    top: 10%;
}

.cruiseWheelInner .bar {
    height: 1.2rem;
    width: 123%;
    position: absolute;
    border-radius: 2px;
    top: 0px;
    left: 0px;
    left: -16%;
    top: 30%;
    border-style: solid;
    z-index: 0;
    border-color: var(--clr-secondary);
    background-color: white;
}

.islandCruiseWheel .cruiseWheelInner .bar {
    height: .5rem;
    left: -17%;
    top: 37%
}

.islandCruiseWheel .cruiseWheelInner .bar::after {
    height: 0.25rem;
    width: 2rem;
    border-style: transparent;
    border-style: transparent;
    border-top-style: solid;
    border-style: solid;
    border-left-style: solid;
    content: "";
    position: absolute;
    top: 3px;
    border-color: var(--clr-secondary);
    left: 5.4rem;
}

.islandCruiseWheel .cruiseWheelInner .bar::before {
    height: 0.25rem;
    width: 2rem;
    border-style: transparent;
    border-style: transparent;
    border-top-style: solid;
    border-style: solid;
    border-left-style: solid;
    content: "";
    position: absolute;
    top: -1px;
    border-color: var(--clr-secondary);
    right: 5.4rem;
}

.cruiseContainer {
    position: relative;
    width: 100%;
    height: 12em;
}

.cruiseContainer.smallCruiseContainer {
    padding: var(--default-padding);
    max-width: 15rem;
}

.cruiseContainer:hover>.cruiseWheel {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}


.cruiseUpperDeck,
.cruiseMiddleDeck,
.cruiseLowerDeck,
.mayanUpperDeck {
    background-color: white;
    z-index: 1;
    width: 35%;
    height: 3.5rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-top-color: var(--clr-third);
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-radius: .5rem;
}

.mayanUpperDeck.small {
    margin-top: .5rem;
    height: .75rem;
}

.cruiseUpperDeckSmall,
.cruiseMiddleDeckSmall,
.cruiseLowerDeckSmall,
.cruiseUpperDeck.small,
.cruiseMiddleDeck.small,
.cruiseLowerDeck.small {
    height: .75rem;
}

.cruiseMiddleDeck.small::before,
.cruiseMiddleDeck.small::after {
    height: .75rem;
}

.cruiseMiddleDeck.small::before {
    left: 0px;
}

.cruiseMiddleDeck.small::after {
    right: 0px;
}

.cruiseMiddleDeck {
    width: 50%;
}

.islandCruiseContainer {
    overflow: hidden;
}

.islandUpperDeck {
    margin-bottom: 1rem;
}

.island {
    position: absolute;
    width: 40%;
    height: 2rem;
    border-style: solid;
    border-color: var(--clr-secondary);
    border-radius: 1rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 30rem;
    height: 40rem;
    top: 5.75rem;
    border-radius: 50%;
    border-width: 2px;
    left: calc(50% - 15rem);
    background-color: white;
}

.island.small {
    top: 1.5rem;
    width: 15rem;
    height: 15rem;
    left: calc(50% - 7.5rem);
}

.waterLines {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    grid-column-gap: 0px;
    margin-top: -4.25rem;
    position: relative;
}

.waterLines.small {
    margin-top: -5rem;
}

.waterLines div {
    border-style: solid;
    border-color: var(--clr-primary);
    border-width: 2px;
    height: 5rem;
    width: 158%;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 2;
    position: relative;
}

.waterLines::after {
    content: '';
    position: absolute;
    bottom: -1.7rem;
    width: 110%;
    height: 2rem;
    background-color: white;
}

.mayanUpperDeck {
    width: 20%;
}

.mayanBottomBar {
    width: 51%;
    border-color: var(--clr-primary);
    border-style: solid;
    border-width: 1px;
    display: flex;
    margin-right: auto;
    margin-left: auto
}

.hotelPort {
    position: absolute;
    left: 40%;
    bottom: 0%;
    height: 70%;
    border-radius: 30% 30% 0% 0%;
    width: 20%;
    background-color: var(--clr-primary);
}

.cruiseLowerDeck {
    width: 100%;
}

.cruiseLowerDeck::before {
    content: '';
    position: absolute;
    background-color: var(--clr-third);
    width: 2px;
    height: 5.4rem;
    transform: rotate(-40deg);
    left: 1.8rem;
    top: -11px;
}

.cruiseLowerDeckSmall.cruiseLowerDeck::before {
    height: 1.65rem;
    top: -2px;
    left: 0.55rem;
    z-index: 1;
}

.cruiseLowerDeckSmall.cruiseLowerDeck::after {
    height: 1.25rem;
    width: calc(100% - .97rem);
}

.cruiseLowerDeck::after {
    content: '';
    position: absolute;
    width: 90%;
    height: 4rem;
    right: 0px;
    top: -2px;
    border-style: solid;
    border-radius: .5rem;
    border-width: 2px;
    border-left-width: 0px;
    border-color: var(--clr-third) var(--clr-third) var(--clr-third) transparent;
    border-top-left-radius: 0px;
    background-color: white;
}

.cruiseUpperDeck::before,
.cruiseMiddleDeck::before,
.mayanUpperDeck::before {
    content: '';
    position: absolute;
    background-color: var(--clr-third);
    width: 2px;
    height: 3.5rem;
    transform: rotate(15deg);
    left: -3px;
    top: 1px;
}

.cruiseUpperDeck::after,
.cruiseMiddleDeck::after,
.mayanUpperDeck::after {
    content: '';
    position: absolute;
    background-color: var(--clr-third);
    width: 2px;
    height: 3.5rem;
    transform: rotate(-15deg);
    right: -3px;
    top: 1px;
}


@media(min-width: 2200px) {
    .waterLines div {
        width: 145%;
    }

    .flag {
        top: -2rem;
    }

    .gridOption:hover>.cruiseContainer .flag {
        top: -2.5rem;
    }
}

@media(max-width:1800px) {}

@media(min-width: 1024px) {

    .slideImage {
        min-height: 100%;
    }

    .showMoreText {
        font-size: var(--default-font-size);
    }
}

@media(min-width: 1440px) {}

@media(max-width:1300px) {
    :root {
        --menuOptionSize: 7.5rem;
        --grid-gap: 2rem;
        --numColumns: auto auto;
        --body-left-margin: 80px;
    }

    .desktopMenuOption,
    .desktopMenuLink {
        font-size: 1rem;
    }

    .bottomBarLogoText {
        left: 48%;
        font-size: 1.6rem;
    }
}

@media(max-width: 1024px) {
    :root {
        --margin-bottom-var: 15px;
        --numColumns: auto;
        --grid-gap: 2rem;
        --default-padding: 10px;
        --default-border-radius: 15px;
        --default-font-size: .9rem;
        --default-font-weight: 500;
        --default-line-height: 1.1rem;
        --menuMargin: 160px;
        --body-left-margin: 110px;
        --menuOptionSize: 6.8em;
    }

    .bottomBarLogoText {
        display: none;
    }

    .imageCard {
        width: 100%;
    }

    .desktopMenuOption,
    .desktopMenuLink {
        font-size: .9rem;
    }

    .desktopDropdown div div a {
        font-size: .9rem
    }

    .grid-container {
        grid-template-columns: var(--numColumns);
    }
}

@media(min-width: 769px) {

    #desktopLand, #desktopCruise {
        box-shadow: 1px 1px 2px 1px rgb(0 0 0 / .3);
        top: calc(var(--mobileNavbarHeight) + 4px);
        margin: 0px;
    }

    .dropdownMenuOption {
        padding: calc(.5 * var(--default-padding)) 1ch;
    }

    .changeEmbarkmentContainer {
        width: auto;
        padding: 0;
        padding-right: var(--default-padding);
        gap: var(--default-padding);
    }

    .changeEmbarkmentIcon, .changeEmbarkmentIcon.MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
    }

    .changeEmbarkmentLabel {
        font-weight: 500;
    }

    .desktopNavbarStripe {
        bottom: 8px;
    }

    .desktopDropdown {
        width: auto;
    }

    .desktopMenuContainer {
        justify-content: space-between;
        height: var(--mobileNavbarHeight);
        align-items: center;
    }

    .desktopMenuOption, .desktopMenuLink {
        padding: calc(.5 * var(--default-padding)) calc(.5 * var(--default-padding));
    }

    .desktopNavbarLogoSpace {
        top: -7px;
        border-top-style: none;
    }

    .logoImg {
        left: -5px;
    }

    .desktopNavbarBottom, #desktopLand, #desktopCruise {
        background-color: var(--clr-background);
    }

    .desktopMenuLink, .desktopMenuOption, .changeEmbarkmentContainer,
    .desktopMenuContainer, .desktopMenuOption a, .changeEmbarkmentLabel,
    .changeEmbarkmentIcon {
        color: var(--clr-primary);
    }

    .desktopNavbarStripe, .desktopNavbarTourStripe {
        background-color: var(--clr-primary);
    }

    .slide.dark {
        justify-content: flex-start;
        padding-top: var(--mobileNavbarHeight);
    }

    .slideImgContainer {
        position:inherit
    }

    .homePanel.fullScreen {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tourCardBundleItem {
        position: relative;
        overflow: hidden;
        border-radius: var(--default-border-radius);
        flex: 1 1 auto;
        display: inline-block;
        height: 40vh;
        min-width: 30%;
        max-width: 50%;
    }

    .loadMoreToursBtn .MuiButton-label {
        font-size: var(--font-size-small);
    }

    .imageCard {
        padding: 20rem 0 0;
        /* max-height: 25%; */
        max-height: 43%;
    }

    .fp-next {
        right: 1rem !important;
    }

    .fp-prev {
        left: 1rem !important;
    }

    .fp-controlArrow {
        top: 43% !important;
    }
}

@media(max-width: 768px) {

    .embarkmentTitle {
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .companyLogoContainer img {
        height: var(--mobileNavbarHeight);
        padding-right: -2px;
        right: -5px;
        position: absolute;
        bottom: -1px;
    }

    :root {
        --numColumns: auto;
        --body-left-margin: 60px;
    }

    .optionsItem .MuiSvgIcon-fontSizeLarge {
        display: none;
    }

    .gridOption {
        flex: 1 1 auto;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .buttonBottomContainer * {
        flex: 1 1 50%;
    }

    .tourBundleGridCentered {
        border: none;
        height: auto;
        grid-template-columns: 65% 35%;
        font-size: var(--default-font-size);
        font-weight: 500;
        user-select: none;
    }

    .tourBundleGridLink {
        font-size: var(--default-font-size);
        color: white;
        padding-right: calc(2*var(--default-padding));
    }

    .tourBundleButtonContainer {
        padding: 0;
    }

    .tourBundleButton {
        width: auto;
    }

    .bundleLogoGrid {
        position: relative;
        display: block;
    }


    .bundleLogo {
        display: inline-block;
        width: 30%;
        margin: var(--default-padding) 0;
        margin-right: var(--default-padding);
        margin-bottom: 0px;
        height: 5.5rem;
        position: relative;
    }

    .bundleLogo .cruiseContainer {
        position: absolute;
        top: 0px;
        padding-left: 0px;
    }

    .bundleLogoItem {
        position: absolute;
        bottom: .2rem;
        width: 100%;
        z-index: 1;
    }

    .bundleLogo.label {
        margin: 0;
        height: auto;
        display: block;
        width: auto;
        border: none;
        padding: 0px;
        font-weight: 500;
        border-radius: 0;
    }

    .bundleLogo {
        margin-right: calc(var(--default-padding) - 2*var(--default-border-width));
    }

    .bundleLogoTextContent {
        display: flex;
        margin: 0 auto;
        font-size: var(--default-font-size);
    }

    .tourBundleGrid {
        grid-template-columns: auto;
        padding: var(--default-padding);
        border-style: solid;
        border-color: var(--clr-primary);
        border-width: var(--default-border-width);
        border-radius: var(--default-border-radius);
        position: relative;
    }

    .mobileNavbar {
        top: 0px;
        display: grid;
        grid-template-columns: 12% 63% auto;
        background-color: var(--clr-primary);
        width: 100%;
        z-index: 1000;
        height: var(--mobileNavbarHeight);
    }

    .mobileNavbar.fixed {
        position: sticky;
    }

    .tourBundleColumn.toursIncluded {
        display: none;
    }

    .tourBundleRowContainer {
        padding-left: var(--default-padding);
        padding-right: var(--default-padding);
        padding-bottom: var(--default-padding);
    }

    .transportationOptionsContainer {
        display: flex;
        flex-direction: column;
        height: calc(100vh - var(--mobileNavbarHeight));
        height: calc(var(--doc-height) - var(--mobileNavbarHeight));

        overflow: hidden;
        padding: 0px;
    }

    .tourBundleTableContainer {
        display: flex;
        flex: 1 1 auto;
    }

    .tourBundleTableContainer .slide .fp-overflow {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .changeEmbarkmentContainer {
        width: auto;
        flex-direction: column;
    }

    .changeEmbarkmentLabel {
        font-size: var(--font-size-xsmall);
    }

    .changeEmbarkmentIcon {
        font-size: 1.8em !important;
    }

    .dropdownContents {
        top: 2.95rem;
    }

    .desktopNavbar {
        display: none;
    }

    .imageCard {
        width: 100%;
        max-height: 100%;
        padding: 2rem 0 0;
    }

    .bottomBar {
        grid-template-columns: auto;
        grid-row-gap: var(--grid-gap);
        padding: calc(2*var(--default-padding));
        max-width: 100%;
        overflow: hidden;
    }

    .bottomBarImgContainer img {
        height: 34%;
    }

    .footerLogoSpace {
        height: 150px;
        width: 150px;
    }

    .bottomBarLogoText {
        font-size: 1.4rem;
        margin-top: 20px;
        left: 35%;
    }

    .imageCardContent {
        transform: none;
        transition: none;
    }

    .imageCardContent>*:not(.imageCardCaption) {
        opacity: 1;
        transition: none;
        transition-delay: 0s;
    }

    .slideShowContainer {
        height: 450px;
        background: black center center no-repeat;
    }

    .hamburgerContainer {
        padding: 0px;
    }

    .fullScreen {
        /* padding-top: var(--mobileNavbarHeight) !important; */
        min-height: calc(100vh - var(--mobileNavbarHeight));
        min-height: calc(var(--doc-height) - var(--mobileNavbarHeight));
    }

    .slideImgContainer {
        position: absolute;
        /* top: var(--mobileNavbarHeight); */
        top: 0px;
    }

    .gridOptionItemButton {
        width: calc(100% - 2 * var(--default-padding));
        display: flex !important;
        justify-self: center !important;
        transition: all 1s;
        position: absolute !important;
        display: flex;
        bottom: var(--default-padding);
        left: var(--default-padding);
        right: var(--default-padding);
        max-width: none;
        width: auto !important;
        max-width: auto !important;
    }

    .singleScreen {
        max-height: 100vh;
        max-height: var(--doc-height);
        overflow: hidden;
    }

    .loadMoreToursBtn .MuiButton-label {
        font-size: .7rem;
    }

    .transportationOptionIcon {
        display: flex;
        font-size: 1.8rem;
    }

    .tourBundleHeaderTitle{
        font-size: .8em;
    }
}

@media(max-width: 425px) {

    :root {
        --grid-gap: 20px;
        --body-left-margin: 20px;
        --default-font-size: .8rem;
        --default-line-height: 1rem;
        --day-size: 1.6rem;
        --font-size-h1: 1.5em;
        --font-size-h2: 1.2em;
        --font-size-h3: 1em;
        --font-size-h4: .9em;
        --font-size-large: .9em;
    }

    .transportationOptionIcon .MuiSvgIcon-root {
        font-size: 1.8rem;
    }

    .imageCard {
        padding: 7rem 0 0;
    }

    .loadMoreToursBtn.yellow {
        background-color: var(--clr-secondary) !important;
    }

    .tourBundleColumn {
        flex: 1 1 31%;
        font-size: var(--font-size-xsmall);
    }

    .accordion.noMargin .MuiAccordionDetails-root {
        padding: 0px !important;
    }

    .accordion.noMargin .MuiAccordionSummary-root {
        min-height: auto !important;
    }

    .accordion.noMargin .MuiAccordionSummary-content {
        margin: 0 !important;
    }

    #fp-nav ul li a span, .fp-slidesNav ul li a span {
        background: var(--clr-secondary) !important;
        height: 4px !important;
        width: 4px !important;
        margin: -2px 0 0 -2px !important;
    }

    #fp-nav ul li a.active span,
    #fp-nav ul li:hover a.active span,
    .fp-slidesNav ul li a.active span,
    .fp-slidesNav ul li:hover a.active span {
        width: 8px !important;
        height: 8px !important;
        margin: -4px 0 0 -4px !important;
    }

    #fp-nav.fp-right {
        right: -2px !important;
    }

    .fp-next {
        right: -10px !important;
    }

    .fp-arrow.fp-prev {
        border-width: 22px 14px 22px !important;
        margin-top: -100px;
    }


    #fp-nav ul li, .fp-slidesNav ul li {
        margin: 0px !important;
    }

    .fp-arrow.fp-next {
        border-width: 22px 14px 22px !important;
        margin-top: -100px;
    }

    .fp-prev {
        left: -10px !important;
    }

    .gridOption {
        min-height: 0rem;
    }

    .grid-container {
        flex-direction: column;
    }

    .tourBundleHeaderContainer {
        display: flex;
        flex-direction: column-reverse;
        gap: var(--default-padding);
    }

    .optionsGrid {
        padding-bottom: var(--default-padding);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    h1 {
        font-size: var(--font-size-h1);
    }

    h2 {
        font-size: var(--font-size-h2);
    }

    h3 {
        font-size: var(--font-size-h3);
    }

    h4 {
        font-size: var(--font-size-h4);
    }


    * {
        user-select: none;
    }

    .faqFilter .filterContainer {
        top: 0;
    }

    .cruiseLowerDeckSmall,
    .cruiseLowerDeckSmall.cruiseLowerDeck::after {
        height: .8rem;
    }

    .cruiseLowerDeckSmall.cruiseLowerDeck::before {
        height: 1rem;
    }

    .bundleLogo .cruiseContainer {
        width: 110%;
        left: -5%;
    }

    .tourBundleGrid {
        margin: var(--margin-bottom-var) 0;
    }

    .tourBundleGridCentered {
        grid-template-columns: 65% 35%;
        font-size: var(--default-font-size);
    }

    .islandUpperDeck {
        top: .5rem;
    }

    .island.small {
        top: 2rem;
        width: 15rem;
        height: 15rem;
        left: calc(50% - 7.5rem);
        width: 10%;
        width: 8rem;
        height: 8rem;
        left: calc(50% - 4.5rem);
    }

    .waterLines.small div {
        width: 180%;
    }

    .tourBundleItems {
        display: flex;
        flex-wrap: wrap;
        height: auto;
    }

    .tourCardBundleItem {
        height: auto;
        width: auto;
        flex: 1 1 auto;
    }

    .slideShowText {
        display: none;
    }

    .slideShowContainer {
        height: 300px;
    }

    .dropdownOption {
        font-size: calc(.8*var(--default-font-size));
    }

    .loadMoreToursBtn {
        width: 100%;
        font-size: 1.2rem;
        border-radius: var(--default-border-radius);
    }

    .navText {
        padding: 15px 0px;
    }

    .companyLogoContainer {
        position: absolute;
        top: 1px;
        padding: 0px;
        left: 87%;
        background: linear-gradient(150deg, rgb(77, 165, 273), #000000 117%);
        border-style: solid;
        height: 2.5rem;
        width: 2.8rem;
        border-width: 2px;
        border-radius: 4rem;
        border-color: black;
        z-index: 1;
        height: calc(var(--mobileNavbarHeight) - 4px);
        width: calc(var(--mobileNavbarHeight) - 4px);
    }

    .dropdownOption.show {
        padding: calc(.8*var(--default-padding));
    }

    .slideShowContainer {
        background-repeat: no-repeat;
        background: black center center;
        background-size: cover;
    }

    .gridOption {
        padding: var(--default-padding);
    }

}