ul {
    margin: 0;
}

.tourBundleRowContainer{
    font-size: var(--font-size-large);
    max-width: 100%;
}

.tourBundleRow {
    display: flex;
    justify-content: space-between;
    gap: var(--default-padding);
    flex-wrap: wrap;
}

.tourBundleColumn {
    box-sizing: border-box;
    text-align: left;
}

.optionsItem {
    display: flex;
}

.optionsItemText {
    flex: 1 1 auto;
    padding-right: var(--default-padding);
}

.optionsItemText.selected {
    font-weight: bold;
}

.tourBundleHeaderContainer *{
    display: inline;
}

.accordion .iconSelected {
    color: var(--clr-fourth) !important;
}

.fullTourDescText {
    color: white;
    text-align: left;
    padding: var(--default-padding);
    padding-top: 4em;
    font-weight: bold;
    background: hsl();
    background: hsl(0 0% 0% / .65);
    display: flex;
    gap: var(--default-padding);
    flex-direction: column;
    /* font-size: var(--font-size-small); */
    height: auto;
    max-height: none;

    transition: all .3s;
}

.fullTourDescText.hidden {
    height: 0;
    max-height: 0;
    transition: all .3s;
}

.buttonBottomContainer {
    position: absolute;
    display: flex;
    bottom: var(--default-padding);
    gap: var(--default-padding);
    justify-content: center;
    left: var(--default-padding);
    right: var(--default-padding);}

@media(max-width: 768px) {
    .fullTourDescText {
        font-size: 0.7em;
        padding-top: 1.5em;
        line-height: 1em;
    }

    .buttonBottomContainer {
        padding: 0;
        left: var(--default-padding);
        right: var(--default-padding);
        width: auto;
    }

}